import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/charactersinfoselectscreen.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Pick a type of person your looking for and you might find information on that character, so see ya!`}</p>
    <p><a parentName="p" {...{
        "href": "goodguys.html"
      }}>{`Good Guys`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      